import React from 'react';
import { connect } from 'react-redux';
import { refreshCurrentUser } from '../actions/user-actions';

class UserProvider extends React.Component {
  componentDidMount() {
    this.props.refreshCurrentUser();
  }
  render() {
    return this.props.children;
  }
}

export default connect(
  null,
  dispatch => ({
    refreshCurrentUser: () => dispatch(refreshCurrentUser())
  })
)(UserProvider);
