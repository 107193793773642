import React from 'react';

import {
  MDBContainer,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  NavLink,
} from 'mdbreact';
import moment from 'moment';
import logo from '../../assets/images/Logo-02@2x.png';

const Footer = () => (
  <footer className="app-footer page-footer">
    <div className="footer-container">
      <div className="footer-top">
        <div className="column-inner mb-4 mb-lg-0">
          <div className="menu-brand">
            <NavLink to="/">
              <img src={logo} className="img-fluid" alt="logo" />
            </NavLink>
          </div>
          <MDBNav
            pills
            className="menu-footer justify-content-center justify-content-md-start"
          >
            <MDBNavItem>
              <MDBNavLink to="/about">About Datashades.info</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem>
              <MDBNavLink to="/contact">Contact</MDBNavLink>
            </MDBNavItem>
          </MDBNav>
        </div>
        <div className="column-inner">
          <div className="menu-brand">
            <span className="color-primary">Follow us on:</span>
          </div>
          <MDBNav
            pills
            className="menu-social justify-content-center justify-content-md-end"
          >
            <MDBNavItem>
              <a
                href="https://twitter.com/datashades"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="tw_logo" />
              </a>
            </MDBNavItem>
            <MDBNavItem>
              <a
                href="https://github.com/DataShades"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="gh_logo" />
              </a>
            </MDBNavItem>
          </MDBNav>
        </div>
      </div>
    </div>
    <div className="footer-copyright text-center">
      <MDBContainer>Datashades Info. {moment().format('Y')}</MDBContainer>
    </div>
  </footer>
);

export default Footer;
