import React from "react";
import { useParams, useHistory } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// React components
import { Element } from "../components";
import * as Service from "../services";

// CKAN Profile components
import CkanData from "./ckan-data";
import CkanStats from "./ckan-stats";
import CkanCkan from "./ckan-ckan";
import { PortalTags } from ".";

// Actions
import {
  getCkanPortal,
  updateCkanPortal,
  selectRevision,
  fetchPortalsList,
} from "../actions/ckan-query-actions";

import {
  getPortalStatistics,
  getPortalPLuginStatistics,
  getPortalVersionStatistics,
  getPortalGraphStats,
} from "../actions/ckan-statistics-actions";

const Portal = (props) => {
  const { id } = useParams();
  const href = decodeURIComponent(id);
  const [activeTab, setActiveTab] = React.useState("tab1");
  const history = useHistory();
  const {
    getCkanPortal,
    getPortalStatistics,
    getPortalPLuginStatistics,
    getPortalVersionStatistics,
    getPortalGraphStats,
    fetchPortalsList,
  } = props;
  const fetchPortalStats = React.useCallback(() => {
    getCkanPortal(href);
    getPortalStatistics(href);
    getPortalPLuginStatistics(href);
    getPortalVersionStatistics(href);
    getPortalGraphStats(href);
    fetchPortalsList();
  }, [
    getCkanPortal,
    getPortalStatistics,
    getPortalPLuginStatistics,
    getPortalVersionStatistics,
    getPortalGraphStats,
    fetchPortalsList,
    href,
  ]);

  React.useEffect(() => {
    fetchPortalStats(href);
  }, [id, fetchPortalStats, href]);

  const switchTabItem = React.useCallback(
    (e) => {
      e.preventDefault();
      const link = e.target.closest("a");
      if (!link) {
        return;
      }
      const nextTab = link.getAttribute("data-tab-id");
      if (nextTab) {
        setActiveTab(nextTab);
      }
    },
    [setActiveTab]
  );

  const runPortalUpdate = React.useCallback(() => {
    props.updateCkanPortal(props.ckanSingle.data, props.user.token);
  }, [props]);

  const removeRevision = React.useCallback(async () => {
    await Service.Portal.remove(props.ckanSingle.data._id, props.user.token);
    fetchPortalStats(href);
    if (props.revisions.length <= 1) {
      history.replace("/");
    }
  }, [props, fetchPortalStats, history, href]);
  return (
    <Element.Profile cardTitle="CKAN Portal">
      <Element.ProfileBanner
        ckan={props.ckanSingle}
        updateHandler={runPortalUpdate}
        removeRevision={removeRevision}
        revisions={props.revisions}
        onRevisionSelect={props.selectRevision}
      />
      <div className="content-body">
        <div className="profile-content">
          <div className="tabpanel m-b-30 content-tabs">
            <Element.NavTabs
              activeTab={activeTab}
              tabsHandler={switchTabItem}
              tabs={[
                { id: "tab1", label: "Overview", iconClassName: "overview" },
                {
                  id: "tab2",
                  label: "Deployment",
                  iconClassName: "deployment",
                },
                {
                  id: "tab3",
                  label: "Statistics",
                  iconClassName: "statistic",
                },
              ].concat(
                props.ckanSingle.data.Tags
                  ? [{ id: "tab4", label: "Tags", iconClassName: "tags" }]
                  : []
              )}
            />
          </div>
          <div className="content-tabs-body card-body clearfix">
            <div className="tab-pane fadeIn active">
              {activeTab === "tab1" && (
                <CkanData
                  ckanRevisions={props.revisions}
                  ckanSingle={props.ckanSingle.data}
                  ckanPortals={props.portals}
                />
              )}
              {activeTab === "tab2" && (
                <CkanCkan
                  version={props.ckanSingle.data.Version}
                  plugins={props.ckanSingle.data.Plugins}
                />
              )}
              {activeTab === "tab3" && (
                <CkanStats
                  ckanSingle={props.ckanSingle.data}
                  ckanPortals={props.portals}
                />
              )}
              {activeTab === "tab4" && (
                <PortalTags tags={props.ckanSingle.data.Tags} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Element.Profile>
  );
};

function mapStateToProps(state) {
  return {
    ckanSingle: state.ckanSingle,
    ckanGraph: state.ckanGraph,
    revisions: state.portalRevisions,
    portals: state.portals,
    user: state.user,
  };
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getPortalStatistics,
      getPortalPLuginStatistics,
      getPortalVersionStatistics,
      getPortalGraphStats,
      getCkanPortal,
      updateCkanPortal,
      selectRevision,
      fetchPortalsList,
    },
    dispatch
  );
}

export default connect(mapStateToProps, matchDispatchToProps)(Portal);
