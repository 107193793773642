/* global gapi */
import { lambda, googleClientId } from '../variables';
const getAuthInstance = () => {
  const instance = gapi.auth2 && gapi.auth2.getAuthInstance();
  if (instance) {
    return Promise.resolve(instance);
  }
  return new Promise((success, error) =>
    gapi.load('auth2', () => {
      const auth = gapi.auth2.init({
        client_id: googleClientId,
      });
      success(auth);
    })
  ).catch(console.warn);
};

export const refreshCurrentUser = () => (dispatch) =>
  getAuthInstance()
    .then((instance) => instance.currentUser.get())
    .then((user) => {
      if (!user.isSignedIn()) {
        throw new ReferenceError('No previous session');
      }
      const profile = user.getBasicProfile();
      const token = user.getAuthResponse().id_token;
      return {
        token,
        name: profile.getName(),
        email: profile.getEmail(),
        imageUrl: profile.getImageUrl(),
      };
    })
    .then((user) =>
      Promise.all([
        user,
        fetch(lambda.authCheck, {
          method: 'POST',
          headers: {
            authorization: user.token,
          },
        }).catch(() => ({ status: 403 })),
      ])
    )
    .then(([user, resp]) => {
      if (resp.status === 200) {
        return dispatch({
          type: 'USER_SIGN_IN',
          user: user,
        });
      }
      throw new Error(`User <${user.email}> is not allowed to sign-in`);
    })
    .catch((error) => {
      if (error instanceof ReferenceError) {
        return;
      }
      signOut()(dispatch).then(() => alert(error));
    });

export const signIn = () => (dispatch) =>
  getAuthInstance()
    .then((instance) => instance.signIn())
    .then(() => refreshCurrentUser()(dispatch));

export const signOut = () => (dispatch) =>
  getAuthInstance()
    .then((instance) => instance.signOut())
    .then(() =>
      dispatch({
        type: 'USER_SIGN_OUT',
      })
    );
