import React from "react";

class Input extends React.PureComponent {
  focusInput(e) {
    const fg = e.target.parentNode;
    fg.classList.add("is-focused");
  }

  blurInput(e) {
    const fg = e.target.parentNode;
    fg.classList.remove("is-focused");
  }

  render() {
    return (
      <div className={"form-group " + this.props.classNameGroup}>
        {this.props.icon && <span className={"icon-" + this.props.icon} />}
        <input
          placeholder={this.props.placeholder}
          type={this.props.type}
          className={"form-control " + this.props.className}
          value={this.props.value}
          aria-label={this.props.ariaLabel}
          name={this.props.name}
          onChange={this.props.changeValue}
          onFocus={this.focusInput}
          onBlur={this.blurInput}
          autoComplete={this.props.autocomplete}
        />
      </div>
    );
  }
}

export default Input;
