import React, { FunctionComponent } from 'react';
import { MDBBtn } from 'mdbreact';

type MileStoneProps = {
  label: string;
  desc: React.ReactNode;
  icon: string;
  reverse: boolean;
  className: string;
};
type RoadMapProps = {
  mileStones: MileStoneProps[];
};

const MileStone: FunctionComponent<MileStoneProps> = ({
  label,
  desc,
  icon,
  reverse,
  className
}) => {
  const content = (
    <div
      className={`mile-stone ${icon ? '' : 'mile-stone--single'} ${
        reverse ? 'row-reverse' : ''
      }`}
    >
      <div className="mile-stone-sign">
        <MDBBtn className="sign-heading">{label}</MDBBtn>
        <div className="sign-content">{desc}</div>
      </div>
      {icon && (
        <img src={icon} className={`milestone-icon ${className}`} alt={label} />
      )}
    </div>
  );

  return content;
};

const RoadMap: FunctionComponent<RoadMapProps> = ({ mileStones }) => (
  <div className="road-map">
    {mileStones.map((item, idx) => {
      const {reverse, ...rest} = item;
      return <MileStone key={idx} reverse={idx % 2 !== 0} {...rest} />;
      }
    )}
  </div>
);

export default React.memo(RoadMap);
