import { combineReducers } from 'redux';

import ckanQueryReducer from './ckanQueryReducer';
import ckanSingleReducer from './ckanSingleReducer';
import ckanStatisticsReducer from './ckanStatisticsReducer';
import ckanPluginStatisticsReducer from './ckanPluginStatisticsReducer';
import ckanVersionStatisticsReducer from './ckanVersionStatisticsReducer';
import ckanGraphReducer from './ckanGraphReducer';
import portalsReducer from './portals-reducer';
import userReducer from './user-reducer';
import portalRevisionsReducer from './portal-revisions-reducer';

const reducers = combineReducers({
  ckanQueryResult: ckanQueryReducer,
  ckanSingle: ckanSingleReducer,
  ckanStatistics: ckanStatisticsReducer,
  statsPlugin: ckanPluginStatisticsReducer,
  statsVersion: ckanVersionStatisticsReducer,
  ckanGraph: ckanGraphReducer,
  portals: portalsReducer,
  user: userReducer,
  portalRevisions: portalRevisionsReducer
});

export default reducers;
