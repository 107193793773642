import React from 'react';

import { Block } from '..';

import { MDBContainer } from 'mdbreact';

const Home = ({ children }) => (
  <>
    <Block.Header>
      <MDBContainer className="subheader">
        <div className="banner-text">
          <h2 className="banner-text-title">CKAN Portals Across the Globe</h2>
          <p className="banner-text-content text-black">
            From here, you can search CKAN data portals across the globe for
            site statistics on datasets, resources, users, organisations and
            more.
          </p>
          {/* <div className="btn-pair">
            <button className="btn switch-view-btn active">Map view</button>
            <button className="btn switch-view-btn">List view</button>
          </div> */}
        </div>
      </MDBContainer>
    </Block.Header>
    <section id="content_outer_wrapper">
      <div id="content_wrapper" className="home-page-content">
        {children}
      </div>
    </section>
    <Block.Footer />
  </>
);

export default Home;
