const initState = [];
export default function portalRevisionsReducer(state = initState, action) {
  switch (action.type) {
    case 'GET_SINGLE_CKAN_PORTAL_REVISIONS':
      return action.payload;
    case 'REJECT_SINGLE_CKAN_PORTAL_REVISIONS':
      return initState;
    default:
      return state;
  }
}
