import React from 'react';

import { Layout, Element } from '..';

import banner from '../../assets/images/banner-about.jpg';
import { HashLink } from 'react-router-hash-link';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

import globeIcon from '../../assets/images/globe icon@2x.png';
import crowdsourcingIcon from '../../assets/images/crowdsourcing icon@2x.png';
import historicalIcon from '../../assets/images/historical data icon@2x.png';
import statisticsIcon from '../../assets/images/statistics icon@2x.png';

const mileStones = [
  {
    label: 'Globally Accessible Open Data',
    desc: (
      <span>
        Datashades.info provides a{' '}
        <span className="accent">publicly-accessible</span> index of metadata
        and statistics on CKAN data portals across{' '}
        <span className="accent">globe</span>.
      </span>
    ),
    icon: globeIcon,
    className: 'mile-stone--icon-globe'
  },
  {
    label: 'Integrated Values of All Metrics',
    desc: (
      <span>
        Data is gathered on{' '}
        <span className="accent">
          portal users, datasets, resources, organisations
        </span>
        , tags and plugins for each portal that can be accessed via the
        individual portal pages on the site.
      </span>
    ),
    icon: statisticsIcon,
    className: 'mile-stone--icon-statistics'
  },
  {
    label: 'Easily-tracked Historical Data',
    desc: (
      <span>
        As the index is refreshed, <span className="accent">old data</span> is
        kept in a <span className="accent">historical index</span> that can be
        explored on any portal page.
      </span>
    ),
    icon: historicalIcon,
    className: 'mile-stone--icon-historical'
  },
  {
    label: 'Crowdsourcing',
    desc: (
      <span>
        Datashades.info uses <span className="accent">crowd-sourcing</span> to
        build its index; if you know of a CKAN data portal not here, just search
        for it, and it'll be added to the site and global statistics.
      </span>
    ),
    icon: crowdsourcingIcon,
    className: 'mile-stone--icon-crowdsourcing'
  },

  {
    label: (
      <HashLink smooth to="/portals#portal-search">
        Explore Now !
      </HashLink>
    ),
    desc: (
      <span>
        So, please have a look around and do not hesitate to search for a portal
        not already present.
      </span>
    )
  }
];

const About = () => {
  return (
    <Layout.Banner
      label="What is Datashades.info?"
      animate={false}
      banner={banner}
    >
      <MDBContainer>
        <MDBRow>
          <MDBCol className="mt-5 mb-5">
            <Element.RoadMap mileStones={mileStones} />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Layout.Banner>
  );
};

export default About;
