import React from 'react';
import { Element } from '../components';
import { MDBDataTable } from 'mdbreact';

const PortalTags = React.memo(props => {
  const { tags } = props;

  const data = Object.entries(tags)
    .sort(
      ([nameLeft, countLeft], [nameRight, countRight]) =>
        countRight - countLeft || nameLeft.localeCompare(nameRight)
    )
    .map(([tag, frequency]) => ({ tag, frequency }));
  if (!data.length) {
    return <Element.Alert.NoData />;
  }
  const columns = [
    { label: 'Tag', field: 'tag' },
    { label: 'Frequency', field: 'frequency' }
  ];

  const tableData = {
    columns,
    rows: data
  };

  return (
    <>
      <h3 className="text-center">Dataset Tags Used in the Portal</h3>
      <MDBDataTable striped hover responsive data={tableData} />
    </>
  );
});

export default PortalTags;
