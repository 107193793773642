import React from "react";

import { Block, Form } from "..";

import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon } from "mdbreact";

const Default = ({ children }) => (
  <div id="app_wrapper">
    <Block.Header>
      <MDBContainer>
        <MDBRow>
          <MDBCol md="12" lg="8">
            <div className="banner-text">
              <h2 className="text-white font-weight-bold">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </h2>
              <p className="mt-sm-3 mt-md-4 text-white">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <MDBBtn
                size="md"
                gradient="ds-green"
                className="btn-primary z-depth-0 mt-sm-3 mt-md-5"
                href="#/learn-more"
              >
                Learn More
                <MDBIcon icon="caret-right" className="ml-3" />
              </MDBBtn>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Form.PortalSearch />
    </Block.Header>
    <section id="content_outer_wrapper">
      <div id="content_wrapper">{children}</div>
    </section>
    <Block.Footer />
  </div>
);

export default Default;
