import React from "react";

import { Layout } from "..";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

const Portal = () => (
  <Layout.Maintenance>
    <MDBContainer>
      <MDBRow>
        <MDBCol>
          <h1>Not found</h1>
          <h3>Specified portal has no associated information.</h3>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </Layout.Maintenance>
);

export { Portal };
