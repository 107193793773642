import { TimeSeries, TimeRange } from "pondjs";

export function initSeries(data = [], options = {}) {
  let srOptions = {};
  let points = Object.assign([], data);
  const default_options = {
    name: "Values",
    columns: ["index", "datasets", "resources", "organisations", "users"],
    points: [],
  };
  srOptions = Object.assign(default_options, options);
  srOptions["points"] = points;
  return new TimeSeries(srOptions);
}

export function initTimeRange(series, tPadding = 0) {
  const range = series.range().toJSON();
  const nRange = [range[0] - tPadding, range[1] + tPadding];
  return new TimeRange(nRange);
}

export function abscisaMax(series, columns) {
  let maxList = [];
  maxList = columns.map((column) => series.max(column));
  return Math.max(...maxList);
}

export function abscisaMin(series, columns) {
  let maxList = [];
  maxList = columns.map((column) => series.min(column));
  return Math.min(...maxList);
}
