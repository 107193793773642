const initState = {
  singlePortalStats: null,
  globalPortalStats: null,
  inProgress: false,
  error: null,
};
export default function ckanStatisticReducer(state = initState, action) {
  switch (action.type) {
    case "START_SINGLE_CKAN_PORTAL_STATISTICS":
      return Object.assign({}, state, { inProgress: true });
    case "GET_SINGLE_CKAN_PORTAL_STATISTICS":
      return Object.assign({}, state, {
        singlePortalStats: action.payload.data,
        inProgress: false,
      });
    case "REJECT_SINGLE_CKAN_PORTAL_STATISTICS":
      return Object.assign({}, state, {
        singlePortalStats: {},
        error: action.payload,
        inProgress: false,
      });
    default:
      return state;
  }
}
