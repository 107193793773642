import React from 'react';

import { Layout, Element } from '../components';

const Home = () => (
  <Layout.Home>
    <Element.PortalLocations />
  </Layout.Home>
);

export default Home;
