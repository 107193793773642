import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Preloaders Components
import { Linear } from "../components/preloaders";

// React components
import { Layout, Element } from "../components";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

// Actions
import {
  initSearchState,
  ckanSearchPortal,
  fetchPortalsList,
} from "../actions/ckan-query-actions";

class CkanSearch extends React.Component {
  state = {
    ckanSearch: "",
    isSearchDisabled: true,
    portalFilter: "",
  };

  handleSearch = (e) => {
    e.preventDefault();
    this.props.ckanSearchPortal(this.state.ckanSearch);
  };

  changeQuery = ({ target: { name, value } }) => {
    this.setState({
      [name]: value,
      isSearchDisabled: !value,
    });
  };

  componentDidMount() {
    this.props.fetchPortalsList();
  }

  onPortalsFilter = (event) =>
    this.setState({
      portalFilter: event.target.value,
    });

  render() {
    return (
      <Layout.Home>
        <MDBContainer>
          <MDBRow>
            <MDBCol>
              {this.props.ckanQueryResult.inProgress && (
                <Linear colorClass="loader-blue" />
              )}
              {this.props.portals.length > 0 && (
                <label>
                  Filter ODP
                  <input
                    className="form-control"
                    onChange={this.onPortalsFilter}
                  />
                </label>
              )}
              <Element.PortalList
                portals={this.props.portals.filter(({ Href }) =>
                  Href.includes(this.state.portalFilter)
                )}
              />
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </Layout.Home>
    );
  }
}

const matchStateToProps = ({ ckanQueryResult, portals }) => ({
  ckanQueryResult,
  portals,
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators(
    { initSearchState, ckanSearchPortal, fetchPortalsList },
    dispatch
  );
}

export default connect(matchStateToProps, matchDispatchToProps)(CkanSearch);
