import React from 'react';

import { lambda } from '../../variables';
import { Layout } from '..';
import {
  NavLink,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBIcon,
  MDBCard,
  MDBCardBody,
  MDBAlert,
} from 'mdbreact';

const props = [
  'firstName',
  'lastName',
  'organisation',
  'email',
  'phone',
  'message',
];

const submit = (form, success) => {
  if (!form.reportValidity()) {
    return;
  }
  const data = props.reduce((data, field) => {
    data[field] = form[field].value;
    return data;
  }, {});

  fetch(lambda.contactUs, {
    method: 'POST',
    body: JSON.stringify(data),
  })
    .then((resp) => resp.json())
    .then(
      () => success(true),
      (err) => console.error(err) || success(false)
    );
};

const ContactUs = () => {
  const form = React.useRef();
  const [isSent, setIsSent] = React.useState();

  return (
    <Layout.Plain>
      <MDBContainer>
        <MDBRow className="contact-us">
          <MDBCol>
            <MDBCard className="m-auto w-75 p-5">
              <MDBCardBody>
                {isSent === true ? (
                  <div>
                    <MDBAlert color="success">
                      Done. We appreciate your participation
                    </MDBAlert>
                    <NavLink to="/" className="btn btn-outline-blue">
                      Back to site
                    </NavLink>
                  </div>
                ) : isSent === false ? (
                  <MDBAlert color="warning">
                    Something went wrong. Please, try again later.
                  </MDBAlert>
                ) : (
                  <form ref={form}>
                    <p className="h1  mb-4">Contact us</p>
                    <div className="grey-text">
                      <MDBInput
                        name="firstName"
                        label="First name"
                        icon="user"
                        required
                      />
                      <MDBInput
                        name="lastName"
                        label="Last name"
                        icon="portrait"
                        required
                      />
                      <MDBInput
                        name="organisation"
                        label="Organisation"
                        icon="tag"
                      />
                      <MDBInput
                        name="email"
                        label="Email"
                        icon="envelope"
                        type="email"
                        required
                      />
                      <MDBInput name="phone" label="Phone" icon="phone" />
                      <MDBInput
                        name="message"
                        label="Message"
                        type="textarea"
                        icon="pencil-alt"
                      />
                    </div>
                    <div className="text-center">
                      <MDBBtn
                        outline
                        color="blue"
                        onClick={() => submit(form.current, setIsSent)}
                      >
                        Send <MDBIcon far icon="paper-plane" className="ml-1" />
                      </MDBBtn>
                    </div>
                  </form>
                )}
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </Layout.Plain>
  );
};

export default ContactUs;
