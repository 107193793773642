import React from "react";

const NavTabs = ({ tabsHandler, activeTab, tabs }) => (
  <ul className="nav nav-tabs nav-justified" onClick={tabsHandler}>
    {tabs.map(({ id, label, iconClassName }) => (
      <li
        className={activeTab === id ? "active" : undefined}
        role="presentation"
        key={id}
      >
        <a href="#none" data-toggle="tab" data-tab-id={id}>
          {iconClassName ? (
            <span className={`tab-icon ${iconClassName}`} />
          ) : (
            ""
          )}
          {label}
        </a>
      </li>
    ))}
  </ul>
);

export default NavTabs;
