import React from "react";
import { connect } from "react-redux";
import { MDBRow, MDBCol, MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { Index } from "pondjs";

// Chart Components
// import SplineChart from '../components/c3-charts/spline-chart';

import { TimeSeriesLineChart, TimeSeriesBarChart } from "../components/charts";
import { Element } from "../components";

function calculatePercentile(list, current) {
  if (!list.length || !current) {
    return 0;
  }
  let total = list.length;
  let listBelow = list.filter((value) => value < current);
  let below = listBelow.length;
  return Math.round((below / total) * 100);
}

function calculateTotal(list) {
  if (list.length === 0) {
    return 0;
  }
  return list.reduce((acc, current) => {
    return acc + current;
  }, 0);
}

function getMetricList(key, portals) {
  let list = [];
  if (key.length === 0 || portals.length === 0) {
    return list;
  }
  portals.forEach((portal) => {
    if (key in portal) {
      list.push(parseInt(portal[key]));
    }
  });
  return list;
}

const Error = ({ children }) => (
  <div>
    <label>Error</label>
    <p>{children}</p>
  </div>
);

const CkanTimeSeriesCharts = ({ currentPortal, revisions }) => {
  let points = [];
  if (revisions.length === 0) {
    return null;
  }

  points = revisions
    .sort(({ CreatedAt: a }, { CreatedAt: b }) => new Date(a) - new Date(b))
    .map((revision) => {
      let created = new Date(new Date(revision.CreatedAt));
      return [
        Index.getIndexString("1d", created),
        revision.DatasetsNumber,
        revision.ResourcesNumber,
        revision.OrgNumber,
        revision.UsersNumber,
      ];
    });
  return (
    <div className="text-center">
      <MDBRow>
        <MDBCol lg="12">
          <TimeSeriesBarChart
            title={"Stats over time"}
            points={points}
            columns={["datasets", "resources", "organisations", "users"]}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol lg="6" md="12">
          <TimeSeriesLineChart
            title={"Datasets"}
            points={points}
            columns={["datasets"]}
          />
        </MDBCol>
        <MDBCol lg="6" md="12">
          <TimeSeriesLineChart
            title={"Resources"}
            points={points}
            columns={["resources"]}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol lg="6" md="12">
          <TimeSeriesLineChart
            title={"Organisations"}
            points={points}
            columns={["organisations"]}
          />
        </MDBCol>
        <MDBCol lg="6" md="12">
          <TimeSeriesLineChart
            title={"Users"}
            points={points}
            columns={["users"]}
          />
        </MDBCol>
      </MDBRow>
    </div>
  );
};

const CkanGeneralStats = ({ currentPortal, portals }) => {
  const generalMetrics = {
    datasets: {
      key: "DatasetsNumber",
      label: "Datasets",
      total: 0,
      percentile: 0,
    },
    resources: {
      key: "ResourcesNumber",
      label: "Resources",
      total: 0,
      percentile: 0,
    },
    organisations: {
      key: "OrgNumber",
      label: "Organisations",
      total: 0,
      percentile: 0,
    },
    users: { key: "UsersNumber", label: "Users", total: 0, percentile: 0 },
  };

  Object.keys(generalMetrics).forEach((metric) => {
    let mattr = generalMetrics[metric].key;
    let list = getMetricList(mattr, portals);
    Object.assign(generalMetrics[metric], {
      total: calculateTotal(list),
      percentile: `${currentPortal[mattr]} (${calculatePercentile(
        list,
        currentPortal[mattr]
      )}%)`,
    });
  });

  const tableColumns = [
    { label: "Metric", field: "metric" },
    { label: "This Portal (percentile)", field: "percentile" },
    { label: "All Portals", field: "total" },
  ];
  const tableRows = [];
  Object.keys(generalMetrics).map((metric) =>
    tableRows.push({
      metric: generalMetrics[metric].label,
      percentile: <span>{generalMetrics[metric].percentile}</span>,
      total: generalMetrics[metric].total,
    })
  );

  return (
    <MDBTable striped hover responsive>
      <MDBTableHead columns={tableColumns} />
      <MDBTableBody rows={tableRows} />
    </MDBTable>
  );
};

const CkanData = ({ ckanSingle, ckanRevisions, ckanPortals }) => {
  return (
    <MDBRow>
      <MDBCol className="clearfix" md="12">
        <MDBCol md="12">
          {ckanSingle.error ? (
            <Error>{ckanSingle.error.error}</Error>
          ) : ckanSingle.data && ckanRevisions.length ? (
            <div>
              <CkanGeneralStats
                currentPortal={ckanSingle.data}
                portals={ckanPortals}
              />
              <CkanTimeSeriesCharts
                currentPortal={ckanSingle.data}
                revisions={ckanRevisions}
              />
            </div>
          ) : (
            <Element.Alert.DataIsLoading />
          )}
        </MDBCol>
      </MDBCol>
    </MDBRow>
  );
};

export default connect(({ ckanSingle }) => ({ ckanSingle }), null)(CkanData);
