import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Map, TileLayer, Marker, Popup } from "react-leaflet";
import { Icon, Popup as NativePopup } from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

// Actions
import { fetchPortalsList } from "../../actions/ckan-query-actions";
import marker from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.min.css";
import "leaflet-gesture-handling";

const iconMarker = new Icon({
  iconUrl: marker,
  shadowUrl: markerShadow,
});

class PortalLocations extends React.PureComponent {
  constructor(props) {
    super(props);
    this.renderMarkers = this.renderMarkers.bind(this);
    this.map = React.createRef();
  }

  componentDidMount() {
    this.props.fetchPortalsList();
  }

  renderMarkers(portals) {
    let markers = [];
    portals.forEach((portal) => {
      if (
        portal.Coordinates !== undefined &&
        portal.Coordinates.hasOwnProperty("lat") &&
        portal.Coordinates.hasOwnProperty("lng")
      ) {
        markers.push(
          <Marker
            key={portal.Href}
            icon={iconMarker}
            position={[portal.Coordinates.lat, portal.Coordinates.lng]}
            portal={{
              href: portal.Href,
              name: portal.SiteInfo.site_title || portal.Href,
            }}
          >
            <Popup>
              <Link to={`/portal/${encodeURIComponent(portal.Href)}`}>
                {portal.Href}
              </Link>
            </Popup>
          </Marker>
        );
      }
    });
    return markers;
  }

  showClusterPopup = (event) => {
    const markerList = event.layer
      .getAllChildMarkers()
      .sort((left, right) =>
        left.options.portal.name.localeCompare(right.options.portal.name)
      )
      .reduce((list, marker) => {
        const { href, name } = marker.options.portal;
        const item = document.createElement("li");
        const link = document.createElement("a");
        link.href = `/portal/${encodeURIComponent(href)}`;
        link.textContent = name;
        item.appendChild(link);
        list.appendChild(item);
        return list;
      }, document.createElement("ol"));

    new NativePopup()
      .setLatLng(event.latlng)
      .setContent(markerList)
      .openOn(this.map.current.leafletElement);
  };

  hideClusterPopup = () => this.map.current.leafletElement.closePopup();

  render() {
    const position = [33.0, 14.0];
    const defaultZoom = 2;
    const maxZoom = 9;
    const markers = this.renderMarkers(this.props.portals);
    return (
      <div id="ckan-global-map">
        <Map
          id="global-map"
          center={position}
          zoom={defaultZoom}
          maxZoom={maxZoom}
          ref={this.map}
          gestureHandling
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup
            showCoverageOnHover={false}
            spiderfyDistanceMultiplier={2}
            spiderLegPolylineOptions={{
              weight: 0,
              opacity: 0,
            }}
            onClusterMouseOver={this.showClusterPopup}
            /* onClusterMouseOut={this.hideClusterPopup} */
            popup={<Popup>Hohoho</Popup>}
          >
            {markers}
          </MarkerClusterGroup>
        </Map>
      </div>
    );
  }
}

const matchStateToProps = ({ portals }) => ({
  portals,
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ fetchPortalsList }, dispatch);
}

export default connect(
  matchStateToProps,
  matchDispatchToProps
)(PortalLocations);
