import axios from "axios";

import { lambda } from "../variables";

type PortalServiceOptions = {
  baseUrl: string;
};

type PortalServiceCache = {
  portals?: any[];
};

class PortalService {
  private baseUrl: string;
  private cache: PortalServiceCache = {};

  constructor(options: PortalServiceOptions) {
    this.baseUrl = options.baseUrl;
  }

  async remove(id: string, token: string): Promise<any> {
    return axios.delete(`${this.baseUrl}/${id}`, {
      headers: {
        authorization: token,
      },
    });
  }

  async list(noCache = false): Promise<any[]> {
    if (!this.cache.portals || noCache) {
      this.cache.portals = await axios.get(`${this.baseUrl}/list`).then(
        (data) => data.data.portals as any[],
        (error) => {
          console.error(error);
          return [];
        }
      );
    }

    return this.cache.portals;
  }
}

export default new PortalService({
  baseUrl: lambda.discovery,
});
