import React from 'react';

import { MDBRow, MDBContainer, MDBCol } from 'mdbreact';

import { Block } from '..';

const Banner = ({ children, label, banner }) => (
  <div id="app_wrapper" className="near-banner">
    <Block.Header />
    <MDBContainer
      fluid
      style={{
        backgroundImage: `linear-gradient(rgba(50,50,50,0.6) 0%, rgba(50,50,50,0.6) 100%), url(${banner})`,
      }}
      className="banner-block"
    >
      <MDBRow>
        <MDBCol className="col-md-8 offset-md-2">
          <h1 className="text-center white-text align-middle banner-heading">
            {label}
          </h1>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    <section id="content_outer_wrapper">
      <div id="content_wrapper">{children}</div>
    </section>
    <Block.Footer />
  </div>
);

export default Banner;
