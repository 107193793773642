import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { C3HorizontalBarChart } from "../components/charts";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";

import { Element } from "../components";

export class CkanStatsTable extends React.Component {
  // Search licenses by name in portals list.
  licensesByName = (name, portals) => {
    let licenses = [];
    if (portals.length === 0) {
      return licenses;
    }
    for (let portal of portals) {
      if (portal.Licenses && portal.Licenses.length) {
        const filtered = portal.Licenses.filter(
          (license) => license.name === name
        );
        if (filtered.length) licenses = licenses.concat(filtered);
      }
    }
    return licenses;
  };

  render() {
    const portal = this.props.currentPortal;
    const portals = this.props.allPortals;
    const portalsCount = portals.length;
    // Deisplay nothing on empty data.
    if (!portal || portalsCount === 0) {
      console.error("No portals", portal, portals);
      return <Element.Alert.NoData />;
    }

    // Table columns.
    const columns = [
      { label: "Licence Name", field: "name" },
      { label: "Number of other portals", field: "total" },
      { label: "Percentage of other portals", field: "percentage" },
    ];
    // Table rows.
    let rows = [];
    const licenses = portal.Licenses;
    if (licenses && licenses.length) {
      rows = licenses.map((license) => {
        const licenseList = this.licensesByName(license.name, portals);
        const count = licenseList.length ? licenseList.length - 1 : 0;
        const percentage =
          count && portalsCount ? Math.round((count / portalsCount) * 100) : 0;
        return {
          name: license.display_name,
          total: count,
          percentage: percentage + "%",
        };
      });
    }
    if (rows.length === 0) {
      return <Element.Alert.NoData />;
    }
    // Sort rows.
    rows.sort((a, b) => b.total - a.total || a.name.localeCompare(b.name));
    return (
      <MDBTable striped hover responsive>
        <MDBTableHead columns={columns} />
        <MDBTableBody rows={rows} />
      </MDBTable>
    );
  }
}

CkanStatsTable.propTypes = {
  currentPortal: PropTypes.object.isRequired,
  allPortals: PropTypes.array.isRequired,
};

class CkanStats extends React.Component {
  render() {
    const currentPortal = this.props.ckanSingle;
    const portals = this.props.ckanPortals;
    if (currentPortal.length === 0 || portals.length === 0) {
      return <Element.Alert.DataIsLoading />;
    }

    let tagsStats = [];
    let datasetsStats = [];
    let usersStats = [];
    const tags = currentPortal.Tags;
    if (tags && tags.length !== 0) {
      tagsStats = Object.keys(tags).map((property) => {
        return {
          label: property,
          value: tags[property],
        };
      });
    }
    const organizations = currentPortal.Organizations;
    if (organizations && organizations.length !== 0) {
      datasetsStats = organizations
        .map((organization) => {
          return {
            label: organization.display_name,
            value: organization.package_count || 0,
          };
        })
        .filter(({ value }) => value);
      usersStats = organizations
        .map((organization) => {
          return {
            label: organization.display_name,
            value: organization.user_count || 0,
          };
        })
        .filter(({ value }) => value);
    }

    return (
      <div>
        <h4>Portal statistics</h4>
        <CkanStatsTable currentPortal={currentPortal} allPortals={portals} />
        <div className="charts-list">
          {tagsStats.length > 0 && (
            <C3HorizontalBarChart
              title="Tags used in portal"
              data={tagsStats}
              categoryLable="Datasets"
            />
          )}
          {datasetsStats.length > 0 && (
            <C3HorizontalBarChart
              title="Organisations vs. datasets"
              data={datasetsStats}
              categoryLable="Datasets"
            />
          )}
          {usersStats.length > 0 && (
            <C3HorizontalBarChart
              title="Organisations vs. users"
              data={usersStats}
              categoryLable="Users"
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ ckanStatistics }) => ({ ckanStatistics });

export default connect(mapStateToProps, null)(CkanStats);
