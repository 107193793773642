import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { FormInline } from 'mdbreact';
import { Form, Element } from '../';

import SearchIconSvg from '../svg/search';
import EraseIconSvg from '../svg/erase';

// Actions
import {
  initSearchState,
  ckanSearchPortal,
} from '../../actions/ckan-query-actions';

class PortalSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      ckanSearch: '',
      isSearchDisabled: true,
      searhPage: this.props.location.pathname === '/portals' ? true : false,
    };
    this.changeQuery = this.changeQuery.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
  }

  componentWillUnmount() {
    this.props.initSearchState();
  }

  handleSearch(e) {
    e.preventDefault();
    this.props.ckanSearchPortal(this.state.ckanSearch);
  }

  changeQuery(value) {
    this.setState({
      ckanSearch: value,
      isSearchDisabled: !value,
    });
  }

  clearSearch() {
    this.setState({
      ckanSearch: '',
      isSearchDisabled: true,
    });
  }

  render() {
    const { data, inProgress } = this.props.ckanQueryResult;
    const error = data && data.error;
    return (
      <div className="search-form">
        {this.props.ckanQueryResult.redirect && (
          <Redirect
            to={{
              pathname: `/portal/${encodeURIComponent(
                this.props.ckanQueryResult.data.Href
              )}`,
              state: {
                from: this.props.location,
                portalData: this.props.ckanQueryResult.data,
              },
            }}
          />
        )}
        <FormInline onSubmit={this.handleSearch} id="portal-search">
          <div className="search-input">
            <span className="search-icon">
              <SearchIconSvg />
            </span>
            <Form.PortalAutocomplete
              value={this.state.ckanSearch}
              changeValue={this.changeQuery}
              name="ckanSearch"
              error={error}
            />
            <span
              className="search-clear"
              onClick={this.clearSearch}
              disabled={this.state.isSearchDisabled || inProgress}
            >
              <EraseIconSvg />
            </span>
          </div>

          <div className="actions-element">
            <button
              type="submit"
              className="btn-search"
              disabled={this.state.isSearchDisabled || inProgress}
            >
              {inProgress ? <Element.Spinner color="danger" /> : 'Search'}
            </button>
          </div>
        </FormInline>
      </div>
    );
  }
}

const matchStateToProps = ({ ckanQueryResult }) => ({
  ckanQueryResult,
});

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ initSearchState, ckanSearchPortal }, dispatch);
}

export default withRouter(
  connect(matchStateToProps, matchDispatchToProps)(PortalSearch)
);
