import React from 'react';

export default () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.3"
      d="M17.0127 19.5293L19.5293 17.0126L14.8935 12.3768L12.3769 14.8934L17.0127 19.5293Z"
      fill="#64B647"
    />
    <path
      opacity="0.3"
      d="M17.0128 19.5293L19.5294 17.0127C20.0683 17.5516 20.0683 18.4253 19.5294 18.9642L18.9643 19.5293C18.4254 20.0682 17.5517 20.0682 17.0128 19.5293Z"
      fill="#64B647"
    />
    <path
      d="M13.2637 15.6673L15.6679 13.2631C15.9935 12.9375 15.9935 12.4096 15.6679 12.084L15.012 11.4281L11.4287 15.0114L12.0846 15.6673C12.4102 15.9929 12.9381 15.9929 13.2637 15.6673Z"
      fill="#64B647"
    />
    <path
      opacity="0.3"
      d="M7.9837 13.7361C4.80662 13.7361 2.23108 11.1605 2.23108 7.98346C2.23108 4.80637 4.80662 2.23083 7.9837 2.23083C11.1608 2.23083 13.7363 4.80637 13.7363 7.98346C13.7363 11.1605 11.1608 13.7361 7.9837 13.7361Z"
      fill="#64B647"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M-0.000177383 7.98349C-0.000177383 12.3926 3.57415 15.967 7.98331 15.967C12.3925 15.967 15.9668 12.3926 15.9668 7.98349C15.9668 3.57433 12.3925 0 7.98331 0C3.57415 0 -0.000177383 3.57433 -0.000177383 7.98349ZM2.23068 7.98349C2.23068 11.1606 4.80622 13.7361 7.98331 13.7361C11.1604 13.7361 13.7359 11.1606 13.7359 7.98349C13.7359 4.8064 11.1604 2.23086 7.98331 2.23086C4.80622 2.23086 2.23068 4.8064 2.23068 7.98349Z"
      fill="#64B647"
    />
    <path
      d="M17.5095 19.8552L19.8552 17.5095C20.0483 17.3164 20.0483 17.0035 19.8552 16.8104C19.6621 16.6174 19.3492 16.6174 19.1561 16.8104L16.8104 19.1562C16.6174 19.3492 16.6174 19.6622 16.8104 19.8552C17.0034 20.0483 17.3164 20.0483 17.5095 19.8552Z"
      fill="#64B647"
    />
  </svg>
);
