import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './reducers';

const middleware = applyMiddleware(ReduxThunk);
const store = createStore(reducers, composeWithDevTools(middleware));

export default store;
