import axios from "axios";
import { lambda } from "../variables";

// import { getPortalStatistics } from './ckan-statistics-actions';

export function initSearchState() {
  return {
    type: "INIT_CKAN_PORTAL_SEARCH",
  };
}

export function ckanSearchPortal(query) {
  return (dispatch) => {
    dispatch({
      type: "START_CKAN_PORTAL_SEARCH",
      payload: true,
    });
    axios
      .get(`${lambda.discovery}`, {
        params: {
          url: query,
        },
      })
      .then(
        (data) => {
          dispatch({
            type: "RUN_CKAN_PORTAL_GET_DATA",
            payload: data || { data: {} },
          });
        },
        (err) => {
          let error = err.response ? err.response.data : { error: err.message };
          console.error(error);
          dispatch({
            type: "REJECT_CKAN_PORTAL_SEARCH",
            payload: error,
          });
        }
      );
  };
}

export function getCkanPortal(id) {
  return async (dispatch) => {
    dispatch({
      type: "START_SINGLE_CKAN_PORTAL",
      payload: true,
    });

    await fetchPortal(decodeURIComponent(id), dispatch);
    await fetchPortalRevisions(decodeURIComponent(id), dispatch);
  };
}

const fetchPortal = async (href, dispatch) => {
  try {
    const data = await axios.get(`${lambda.discovery}/${btoa(href)}`);
    dispatch({
      type: "GET_SINGLE_CKAN_PORTAL",
      payload: data.data,
    });
  } catch (err) {
    console.error("Get CKAN portal:", err);
    let error = err.response ? err.response.data : { message: err.error };
    dispatch({
      type: "REJECT_SINGLE_CKAN_PORTAL",
      payload: error,
    });
    return;
  }
};
const fetchPortalRevisions = async (href, dispatch) => {
  try {
    const data = await axios.get(`${lambda.discovery}/${btoa(href)}/revisions`);
    dispatch({
      type: "GET_SINGLE_CKAN_PORTAL_REVISIONS",
      payload: data.data.revisions,
    });
  } catch (err) {
    console.error("Get CKAN portal:", err);
    let error = err.response ? err.response.data : { message: err.error };
    dispatch({
      type: "REJECT_SINGLE_CKAN_PORTAL_REVISIONS",
      payload: error,
    });
    return;
  }
};

export function updateCkanPortal(ckanData, token) {
  return (dispatch) => {
    axios
      .put(
        `${lambda.discovery}/${btoa(ckanData.Href)}`,
        {},
        {
          headers: {
            authorization: token,
          },
        }
      )
      .then(
        (data) => {
          dispatch({
            type: "START_UPDATE_CKAN_PORTAL",
            payload: {
              data: ckanData,
              updateInProccess: true,
            },
          });
        },
        (err) => {
          let error = err.response ? err.response.data : { error: err.message };
          console.error(error);
          dispatch({
            type: "REJECT_UPDATE_CKAN_PORTAL",
            payload: {
              updateInProccess: false,
              error: error,
            },
          });
        }
      );
  };
}

export function fetchPortalsList() {
  return (dispatch) => {
    axios.get(`${lambda.discovery}/list`).then((data) => {
      dispatch({ type: "PORTALS_SET", payload: data.data.portals });
    });
  };
}

export const selectRevision = (revision) => (dispatch) =>
  dispatch({
    type: "GET_SINGLE_CKAN_PORTAL",
    payload: revision,
  });
