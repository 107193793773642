import React, { FunctionComponent } from 'react';

type SpinnerColors =
  | 'black'
  | 'primary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info';

type SpinnerProps = {
  color?: SpinnerColors;
};

const Spinner: FunctionComponent<SpinnerProps> = ({color = 'black'}) => (
  <div className={`spinner-border text-${color}`} role="status">
    <span className="sr-only">Loading...</span>
  </div>
);

export default Spinner;
