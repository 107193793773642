const initState = {
  data: null,
  inProgress: false,
  redirect: false
};
export default function ckanQueryReducer(state = initState, action) {
  switch (action.type) {
    case 'INIT_CKAN_PORTAL_SEARCH':
      return Object.assign({}, state, initState);
    case 'START_CKAN_PORTAL_SEARCH':
      return Object.assign({}, state, { inProgress: true, data: {} });
    case 'RUN_CKAN_PORTAL_GET_DATA':
      return Object.assign({}, state, {
        data: action.payload.data,
        inProgress: false,
        redirect: true
      });
    case 'REJECT_CKAN_PORTAL_SEARCH':
      return Object.assign({}, state, {
        data: action.payload,
        inProgress: false,
        redirect: false
      });
    default:
      return state;
  }
}
