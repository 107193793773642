import config from './config.json';

const {google_client_id, lambda_endpoints} = config;

export const googleClientId = google_client_id;
export const lambda = {
  authCheck: lambda_endpoints.auth,
  discovery: lambda_endpoints.discovery,
  contactUs: lambda_endpoints.contact_us
};
