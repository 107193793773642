import React from 'react';

import { Block } from '..';

import { Container } from 'mdbreact';

const Plain = ({ children, animate = false }) => (
  <div id="app_wrapper">
    <Block.Header animate={animate} />
    <section id="content_outer_wrapper">
      <div id="content_wrapper">
        <Container>{children}</Container>
      </div>
    </section>
    <Block.Footer />
  </div>
);

export default Plain;
