import React from "react";
import { connect } from "react-redux";
import { AccessCheck } from ".";
import { MDBDataTable } from "mdbreact";
import { Element } from "../components";

const calculatePluginsStats = (data, portals, email) =>
  Object.entries(data || {})
    .sort(([, left], [, right]) => right - left)
    .map(([name, percent]) => {
      const row = {
        name,
        percent,
      };
      if (email) {
        row.number = (portals || []).filter(({ Plugins = [] }) =>
          Plugins.some((plugin) => plugin === name)
        ).length;
      }
      return row;
    });

const CkanCkan = (props) => {
  const {
    version = "0.0.0",
    plugins,
    statsVersion,
    statsPlugin: { data },
    portals,
  } = props;

  let rows = React.useMemo(
    () => calculatePluginsStats(data, portals, props.user.email),
    [data, portals, props.user]
  );

  if (!plugins || !data) {
    return <Element.Alert.DataIsLoading />;
  }
  if (!plugins.length || !Object.keys(data).length) {
    return <Element.Alert.NoData />;
  }
  const majorVersion = parseFloat(version).toFixed(1);

  var portalsCkanVersion = portals.filter(
    ({ Version }) => parseFloat(Version).toFixed(1) === majorVersion
  ).length;

  const tableData = {
    columns: [
      { label: "Plugin name", field: "name" },
      { label: "Percentage use", field: "percent" },
    ].concat(
      props.user.email ? [{ label: "Portals number use", field: "number" }] : []
    ),
    rows: rows,
  };
  return (
    <>
      <AccessCheck userRequired={true}>
        <h2>Version: {version}</h2>
        <p>
          {portalsCkanVersion} ({(statsVersion.data || {})[majorVersion]}%) of
          Open Data Portals use CKAN v{majorVersion}
        </p>
      </AccessCheck>
      <h3 className="text-center">List of used plugins</h3>
      <MDBDataTable striped hover data={tableData} />
    </>
  );
};

export default connect(
  ({ statsPlugin, statsVersion, portals, user }) => ({
    statsPlugin,
    statsVersion,
    portals,
    user,
  }),
  null
)(CkanCkan);
