import React from 'react';

import { Layout } from '../components';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';

const Maintenance = () => (
  <Layout.Maintenance>
    <MDBContainer>
      <MDBRow>
        <MDBCol>
          <h1>Coming Soon</h1>
          <h3>
            This feature/information is forthcoming. Please check back again in
            a few days.
          </h3>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  </Layout.Maintenance>
);

export default Maintenance;
