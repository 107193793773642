const initState = {
  data: {},
  inProgress: false,
  redirect: false,
  updateInProccess: false,
  error: null,
};
export default function ckanSingleReducer(state = initState, action) {
  switch (action.type) {
    case "START_SINGLE_CKAN_PORTAL":
      return Object.assign({}, state, { inProgress: true });
    case "GET_SINGLE_CKAN_PORTAL":
      return Object.assign({}, state, {
        data: action.payload,
        error: null,
        inProgress: false,
        redirect: false,
        updateInProccess: false,
      });
    case "REJECT_SINGLE_CKAN_PORTAL":
      return Object.assign({}, state, {
        error: action.payload,
        inProgress: false,
        redirect: true,
      });
    case "START_UPDATE_CKAN_PORTAL":
      return Object.assign({}, state, action.payload);
    case "REJECT_UPDATE_CKAN_PORTAL":
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
}
