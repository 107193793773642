import React from 'react';

import { Block } from '..';

const Maintenance = ({ children }) => (
  <div id="app_wrapper" className="page-maintenance">
    <Block.Header animate={false} />
    <section id="content_outer_wrapper">
      <div id="content_wrapper">{children}</div>
    </section>
    <Block.Footer />
  </div>
);

export default Maintenance;
