import React, { Component } from "react";

import { initSeries, initTimeRange, abscisaMax, abscisaMin } from "./helpers";
import {
  Charts,
  ChartContainer,
  ChartRow,
  YAxis,
  BarChart,
  Resizable,
  Legend,
  styler,
} from "react-timeseries-charts";
import { format } from "d3-format";

class TimeSeriesBarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      series: null,
      timerange: null,
      hightlight: null,
      selection: null,
    };
  }

  render() {
    const bars = [
      { key: "datasets", label: "Datasets" },
      { key: "resources", label: "Resources" },
      { key: "organisations", label: "Organisations" },
      { key: "users", label: "Users" },
    ];
    const styles = styler([
      { key: "datasets", color: "#eb5355" },
      { key: "resources", color: "#79c377" },
      { key: "organisations", color: "#b27aba" },
      { key: "users", color: "#a5c8e1" },
    ]);
    const timePadding = 60 * 60 * 24 * 100;
    const columns = this.props.columns;
    const points = this.props.points;

    const series = this.state.series ? this.state.series : initSeries(points);
    const timerange = this.state.timerange
      ? this.state.timerange
          : initTimeRange(series, timePadding);
    const ymax = abscisaMax(series, columns);
    const ymin = abscisaMin(series, columns);
    // const maxTime = timerange.end();
    // const minTime = timerange.begin();
    // const minDuration = timePadding;
    const formatter = format(".2s");
    const infoWidth = 120;
    const size = 10;
    // const margin = 0;
    const highlight = this.state.highlight;
    let infoValues = [];
    if (highlight) {
      const infoValue = formatter(highlight.event.get(highlight.column));
      const infoLabel =
        highlight.column.charAt(0).toUpperCase() + highlight.column.slice(1);
      infoValues = [{ label: infoLabel, value: infoValue }];
    }
    const selection = this.state.selection;
    return (
      <div className="w-100 chart-wrapper mb-4">
        <h3 className="chart-title text-center">{this.props.title}</h3>
        <div className="mt-3 chart-legend-wrapper legend-center">
          <Resizable>
            <ChartContainer
              timeRange={timerange}
              enablePanZoom
              showGrid={false}
              timeAxisAngledLabels={true}
              timeAxisHeight={70}
              // maxTime={maxTime}
              // minTime={minTime}
              // minDuration={minDuration}
              onBackgroundClick={() => this.setState({ selection: null })}
              onTimeRangeChanged={(timerange) => this.setState({ timerange })}
              onChartResize={(width) => this.handleChartResize(width)}
            >
              <ChartRow height="300">
                <YAxis
                  id="values"
                  label="Values"
                  classed="value"
                  min={ymin}
                  max={ymax}
                  width="80"
                  type="linear"
                  labelOffset={10}
                />
                <Charts>
                  {bars.map((bar, index) => {
                    return (
                      <BarChart
                        key={index}
                        axis="values"
                        style={styles}
                        columns={[bar.key]}
                        series={series}
                        size={size}
                        offset={index * size}
                        info={infoValues}
                        infoTimeFormat="%d %B %Y"
                        infoWidth={infoWidth}
                        highlighted={highlight}
                        visible={
                          selection === null ||
                          (selection && selection.column === bar.key)
                            ? true
                            : false
                        }
                        selection={this.state.selection}
                        onHighlightChange={(highlight) =>
                          this.setState({ highlight: highlight })
                        }
                        onSelectionChange={(selection) => {
                          this.setState({ selection });
                        }}
                      />
                    );
                  })}
                </Charts>
              </ChartRow>
            </ChartContainer>
          </Resizable>
          <div className="mt-3 chart-legend-wrapper legend-center">
            <Legend
              type="swatch"
              align="left"
              style={styles}
              highlight={
                this.state.highlight ? this.state.highlight.column : null
              }
              selection={selection ? selection.column : null}
              onSelectionChange={(selection) =>
                this.setState({ selection: { column: selection } })
              }
              categories={bars}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default TimeSeriesBarChart;
