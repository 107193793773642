import React from "react";
import { initSeries, initTimeRange, abscisaMax, abscisaMin } from "./helpers";
import {
  Charts,
  ChartContainer,
  ChartRow,
  YAxis,
  Resizable,
  LineChart,
  ScatterChart,
  styler,
} from "react-timeseries-charts";
import { format } from "d3-format";

class TimeSeriesLineChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      series: null,
      timerange: null,
      hover: null,
      highlight: null,
    };
    this.handleMouseNear = this.handleMouseNear.bind(this);
  }

  handleMouseNear(point) {
    this.setState({
      highlight: point,
    });
  }

  render() {
    const styles = styler([
      { key: "datasets", color: "#eb5355", width: 2 },
      { key: "resources", color: "#79c377", width: 2 },
      { key: "organisations", color: "#b27aba", width: 2 },
      { key: "users", color: "#a5c8e1", width: 2 },
    ]);
    const columns = this.props.columns;
    const points = this.props.points;
    const series = this.state.series ? this.state.series : initSeries(points);
    const timerange = this.state.timerange
      ? this.state.timerange
      : initTimeRange(series);
    const ymax = abscisaMax(series, columns);
    const ymin = abscisaMin(series, columns);
    const formatter = format(".2s");
    const highlight = this.state.highlight;
    let infoValues = [];
    if (highlight) {
      const infoValue = formatter(highlight.event.get(highlight.column));
      const infoLabel =
        highlight.column.charAt(0).toUpperCase() + highlight.column.slice(1);
      infoValues = [{ label: infoLabel, value: infoValue }];
    }
    return (
      <div className="w-100 chart-wrapper">
        <h3 className="chart-title text-center">{this.props.title}</h3>
        <Resizable>
          <ChartContainer
            timeRange={timerange}
            enablePanZoom={true}
            timeAxisAngledLabels={true}
            timeAxisHeight={70}
            onTimeRangeChanged={(timerange) => this.setState({ timerange })}
          >
            <ChartRow height="200">
              <YAxis
                id="y"
                label="Values"
                classed="values"
                min={ymin}
                max={ymax}
                width="70"
                type="linear"
              />
              <Charts>
                <LineChart
                  axis="y"
                  breakLine={true}
                  series={series}
                  columns={columns}
                  style={styles}
                />
                <ScatterChart
                  axis="y"
                  series={series}
                  columns={columns}
                  style={styles}
                  onMouseNear={(p) => this.handleMouseNear(p)}
                  highlight={this.state.highlight}
                  radius={3}
                  info={infoValues}
                  infoHeight={30}
                  infoWidth={120}
                  format=".2s"
                  infoTimeFormat="%d %B %Y"
                />
              </Charts>
            </ChartRow>
          </ChartContainer>
        </Resizable>
      </div>
    );
  }
}

export default TimeSeriesLineChart;
