import React from "react";
import { Link } from "react-router-dom";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import { utc } from "moment";

export default class PortalList extends React.Component {
  render() {
    if (!this.props.portals.length) {
      return null;
    }

    return (
      <MDBTable responsive striped hover fixed className="m-auto">
        <MDBTableHead>
          <tr>
            <th>Portal</th>
            <th>Last updated (UTC)</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {this.props.portals.map((portal) => (
            <tr key={portal.Href}>
              <td>
                <Link to={"/portal/" + encodeURIComponent(portal.Href)}>
                  {new URL(portal.Href).hostname}
                </Link>
              </td>
              <td>{utc(portal.CreatedAt).format("DD MMMM, YYYY, HH:mm")}</td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
    );
  }
}
