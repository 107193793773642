import React, { FunctionComponent } from 'react';

import { MDBAlert } from 'mdbreact';

type AlertProps = {
  type: string;
    heading?: string;
    children?: any;
};

const Alert: FunctionComponent<AlertProps> = (props: AlertProps) => (
  <MDBAlert color={props.type}>
    {props.heading && (
      <>
        <h4 className="alert-heading text-left">{props.heading}</h4>
        <hr />
      </>
    )}
    {props.children}
  </MDBAlert>
);

export const NoData = () => (
  <Alert
    type="warning"
    heading="Unable to retrieve needed data from this portal."
  >
    <p>Please contact site admin to have them re-index this site.</p>
  </Alert>
);

export const UpdateIsScheduled = () => (
  <Alert type="info" heading="Portal update has been scheduled.">
    <p>
      Data will be updated in the near future. Please feel free to visit other
      portals in the meantime.
    </p>
  </Alert>
);

export const DataIsLoading = () => (
  <Alert type="info" heading="Information is still loading">
    <p>Don't leave the page, it will be updated in a moment.</p>
  </Alert>
);
