import React from 'react';

import { Block } from '..';

const Profile = ({ children }) => (
  <div id="app_wrapper" className="ckan-profile">
    <Block.Header />
    <section>
      <div id="content">{children}</div>
    </section>
    <Block.Footer />
  </div>
);

export default Profile;
