import React from 'react';

class C3HorizontalBarChart extends React.Component {
  componentDidMount() {
    this.c3 = require('c3');
    this.generateChart();
  }
  componentDidUpdate() {
    this.generateChart();
  }
  componentWillUnmount() {
    this.chart = this.chart.destroy();
  }
  generateChart() {
    const categoryLable = this.props.categoryLable;
    let data = this.props.data;
    data = data.sort(({ value: a }, { value: b }) => b - a).slice(0, 10);
    let dataLabels = [];
    let dataValues = [];
    data.forEach(item => {
      dataLabels.push(item.label);
      dataValues.push(item.value);
    });
    const options = {
      data: {
        x: 'labels',
        columns: [['labels', ...dataLabels], ['values', ...dataValues]],
        names: {
          values: categoryLable
        },
        type: 'bar'
      },
      axis: {
        x: {
          type: 'category'
        },
        y: {
          label: {
            text: categoryLable,
            position: 'outer-center'
          }
        },
        rotated: true
      },
      legend: {
        show: false
      },
      zoom: {
        // enabled: true
        // rescale: true
      }
    };
    this.chart = this.c3.generate({
      bindto: this.node,
      ...options
    });
  }
  render() {
    const width = this.props.width ? this.props.width : '100%';
    const height = this.props.height ? this.props.height : '350px';
    const style = {
      width: width,
      height: height
    };
    return (
      <div className="w-100 chart-wrapper mb-4">
        <h5 className="chart-title text-center">{this.props.title}</h5>
        <div
          className="chart-container"
          style={style}
          ref={node => (this.node = node)}
        />
      </div>
    );
  }
}

export default C3HorizontalBarChart;
