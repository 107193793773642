import React, { FunctionComponent, SyntheticEvent } from 'react';
import * as Service from '../../services';
import { Form } from '..';

type PortalAutocompleteProps = {
  name: string;
  value: string;
  error?: string;
  changeValue: CallableFunction;
};

type AutocompleteSuggestionsProps = {
  showAll?: boolean;
  value: any;
  data: any[];
  checker: (value: any, item: any) => boolean;
  onSelect: CallableFunction;
};

const AutocompleteSuggestions: FunctionComponent<AutocompleteSuggestionsProps> = (
  props
) => {
  if (!props.value && !props.showAll) {
    return null;
  }
  const results = props.data
    .filter((item) => props.checker(props.value, item))
    .slice(0, 10);
  if (!results.length) {
    return null;
  }
  return (
    <ul className="portal-search-autocomplete">
      {results.map((item) => (
        <li key={item._id} onClick={() => props.onSelect(item.Href)}>
          {item.Href}
        </li>
      ))}
    </ul>
  );
};

const PortalAutocomplete: FunctionComponent<PortalAutocompleteProps> = (
  props
) => {
  const [portals, setPortals] = React.useState<any[]>([]);
  React.useEffect(() => {
    Service.Portal.list().then(setPortals);
  }, []);

  return (
    <>
      <div
        className={`tooltip bs-tooltip-bottom ${props.error ? 'show' : ''}`}
        role="tooltip"
      >
        <div className="arrow" />
        <div className="tooltip-inner">{props.error}</div>
      </div>
      <Form.Input
        name={props.name}
        className={`shadow-none ${props.error ? 'invalid-input' : ''}`}
        type="text"
        placeholder="Enter CKAN Portal URL"
        ariaLabel="Enter CKAN Portal URL"
        value={props.value}
        changeValue={(event: SyntheticEvent) =>
          props.changeValue((event.target as any).value)
        }
        classNameGroup={`border-${props.error ? 'danger' : 'primary'}`}
        icon="search"
        autocomplete="off"
      />
      <AutocompleteSuggestions
        value={props.value}
        data={portals}
        checker={(value: string, portal: any) =>
          portal.Href.includes(value) && portal.Href !== value
        }
        onSelect={props.changeValue}
      />
    </>
  );
};

export default PortalAutocomplete;
