import axios from "axios";
import { lambda } from "../variables";

export function getPortalStatistics(id) {
  return (dispatch) => {
    dispatch({
      type: "START_SINGLE_CKAN_PORTAL_STATISTICS",
    });
    axios
      .get(`${lambda.discovery}/stats/overall`, {
        params: {
          href: id,
        },
      })
      .then((data) => {
        dispatch({
          type: "GET_SINGLE_CKAN_PORTAL_STATISTICS",
          payload: { data: data.data.stats.overall },
        });
      })
      .catch((error) => {
        dispatch({
          type: "REJECT_SINGLE_CKAN_PORTAL_STATISTICS",
          payload: error,
        });
      });
  };
}

export function getPortalPLuginStatistics(id) {
  return (dispatch) => {
    dispatch({
      type: "START_SINGLE_CKAN_PORTAL_PLUGIN_STATISTICS",
    });
    axios
      .get(`${lambda.discovery}/stats/plugin`, {
        params: {
          href: id,
        },
      })
      .then((data) => {
        dispatch({
          type: "GET_SINGLE_CKAN_PORTAL_PLUGIN_STATISTICS",
          payload: { data: data.data.stats.plugin },
        });
      })
      .catch((error) => {
        dispatch({
          type: "REJECT_SINGLE_CKAN_PORTAL_PLUGIN_STATISTICS",
          payload: error,
        });
      });
  };
}

export function getPortalVersionStatistics(id) {
  return (dispatch) => {
    dispatch({
      type: "START_SINGLE_CKAN_PORTAL_VERSION_STATISTICS",
    });
    axios
      .get(`${lambda.discovery}/stats/version`, {
        params: {
          href: id,
        },
      })
      .then((data) => {
        dispatch({
          type: "GET_SINGLE_CKAN_PORTAL_VERSION_STATISTICS",
          payload: { data: data.data.stats.version },
        });
      })
      .catch((error) => {
        dispatch({
          type: "REJECT_SINGLE_CKAN_PORTAL_VERSION_STATISTICS",
          payload: error,
        });
      });
  };
}

export function getPortalGraphStats(id) {
  return (dispatch) => {
    dispatch({
      type: "START_SINGLE_CKAN_GRAPH_STATISTICS",
    });
    axios
      .get(`${lambda.discovery}/stats/gaussian`, {
        params: {
          href: id,
        },
      })
      .then((data) => {
        dispatch({
          type: "GET_SINGLE_CKAN_GRAPH_STATISTICS",
          payload: { data: data.data.stats.gaussian },
        });
      })
      .catch((error) => {
        dispatch({
          type: "REJECT_SINGLE_CKAN_GRAPH_STATISTICS",
          payload: error,
        });
      });
  };
}
