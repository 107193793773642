import { connect } from 'react-redux';

const AccessCheck = props => {
  const { userRequired, anonimousOnly, user, children } = props;

  if (userRequired && !user.email) {
    return null;
  }
  if (anonimousOnly && user.email) {
    return null;
  }
  return children;
};

export default connect(state => ({ user: state.user }))(AccessCheck);
