const initState = {
  data: null,
  inProgress: false,
  error: null,
};

export default function ckanStatisticReducer(state = initState, action) {
  switch (action.type) {
    case "START_SINGLE_CKAN_PORTAL_PLUGIN_STATISTICS":
      return Object.assign({}, state, { inProgress: true });
    case "GET_SINGLE_CKAN_PORTAL_PLUGIN_STATISTICS":
      return Object.assign({}, state, {
        data: action.payload.data,
        inProgress: false,
      });
    case "REJECT_SINGLE_CKAN_PORTAL_PLUGIN_STATISTICS":
      return Object.assign({}, state, {
        data: {},
        error: action.payload,
        inProgress: false,
      });
    default:
      return state;
  }
}
