import React from 'react';

export default () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.205 15.0954L9.90975 17.4002C9.4631 17.8475 8.85618 18.0978 8.22404 18.0954H5.99547C5.36333 18.0978 4.75641 17.8475 4.30975 17.4002L1.45261 14.543C0.528817 13.609 0.528817 12.1056 1.45261 11.1716L4.87167 7.76208L12.205 15.0954Z"
      fill="#BFBFBF"
    />
    <path
      opacity="0.3"
      d="M19.8235 7.47636L12.2044 15.0954L4.87109 7.76208L12.4901 0.143014C12.6652 -0.0410927 12.9564 -0.0484142 13.1404 0.126675C13.146 0.131988 13.1515 0.137434 13.1568 0.143014L19.8235 6.80971C19.9186 6.9 20 7.02119 20.0002 7.14266C20.0004 7.25651 19.9253 7.37062 19.8406 7.45976C19.8352 7.46551 19.8295 7.47114 19.8235 7.47636Z"
      fill="#BFBFBF"
    />
    <path
      opacity="0.3"
      d="M17.6191 20.0002H0.476205C0.213214 20.0002 0 19.787 0 19.5241C0 19.2611 0.213214 19.0479 0.476205 19.0479H17.6191C17.8821 19.0479 18.0953 19.2611 18.0953 19.5241C18.0953 19.787 17.8821 20.0002 17.6191 20.0002Z"
      fill="#BFBFBF"
    />
  </svg>
);
